import React from "react";
import "./App.scss";
import { SocialIcon } from "react-social-icons";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  app: {
    textAlign: "center",
    background: "linear-gradient(30deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
    backgroundSize: "400% 400%",
    animation: "$gradient 15s ease infinite",
    minHeight: "100vh",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  socialFooter: {
    display: "flex",
    position: "sticky",
    bottom: "20px",
    mixBlendMode: "overlay",
  },
  socialIcon: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
});

const App: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.app}>
      <div className={styles.mainContent}>
        <Typography variant="h5" component="h3">
          Hi, my name is
        </Typography>
        <Typography variant="h2" component="h1" gutterBottom>
          Philip Arpin
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          With over 15 years of experience in product management, engineering,
          and leadership, Arpin has worked with industry giants and innovative
          startups, consistently driving success and growth. He&apos;s not only
          an expert in delivering top-notch products but also a master of
          optimizing engineering workflows to maximize efficiency.
        </Typography>
      </div>
      <div className={styles.socialFooter}>
        <SocialIcon
          className={styles.socialIcon}
          url="mailto:phil@arpin.xyz"
          fgColor="black"
          bgColor="white"
        />
        <SocialIcon
          className={styles.socialIcon}
          url="https://linkedin.com/in/philiparpin"
          fgColor="black"
          bgColor="white"
        />
        <SocialIcon
          className={styles.socialIcon}
          url="https://github.com/philiparpin"
          fgColor="black"
          bgColor="white"
        />
        <SocialIcon
          className={styles.socialIcon}
          url="https://twitter.com/philiparpin"
          fgColor="black"
          bgColor="white"
        />
      </div>
    </div>
  );
};

export default App;
